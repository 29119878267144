import { Button, Col, Divider, Row, Modal, Spin, Icon } from 'antd';
import React, { Component } from 'react';
import { CONFIG } from '../../../config/app-config';
import moment from 'moment';
import _ from 'lodash';
import center from '@turf/center';
import Map from '../../../components/Map';
import EditNationalMapSiteForm from './EditNationalMapSiteForm';

class NationalMapDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMapAreaModal: false,
            showEditSiteModal: false,
            profileImageLoading: true,
            bannerImageLoading: true,
            climateChampionImageLoading: true
        }
    }

    componentWillReceiveProps = () => {
        this.setState({
            profileImageLoading: true,
            bannerImageLoading: true,
            climateChampionImageLoading: true
        });
    }

    handleCancelEditSite = () => {
        this.setState({ showEditSiteModal: false });
    }

    viewSiteAreaMap = (site) => {
        let centerPoint = [150.82520658489005, -33.850000647657914]; // Default Value: coordinates around Sydney
        let zoom = 10;

        if (Object.keys(site.geometry).length) {
            centerPoint = center(site).geometry.coordinates;
            zoom = 15;
        }

        return (
            <div>
                {this.state.showMapAreaModal &&
                    <Modal
                        title='View Map Area'
                        visible={this.state.showMapAreaModal}
                        width={'80vw'}
                        footer={null}
                        onCancel={() => this.setState({ showMapAreaModal: false })}
                    >
                        <Map geometry={site.geometry} center={centerPoint} zoom={zoom} />
                    </Modal>
                }
            </div>
        );
    }

    editSiteForm = () => {
        return (            
            <div>
                {
                    this.state.showEditSiteModal &&
                    <EditNationalMapSiteForm
                        site={this.props.site}
                        onEditSite={this.handleEditSite}
                        visible={this.state.showEditSiteModal}
                        onCancel={this.handleCancelEditSite}
                        callBack={this.props.loadNationalMapDetails}
                    />
                }
            </div>        
        );
    }

    imageLoading = (isLoading) => {
        const antIcon = <Icon className='epar__spinner' type="loading" style={{ fontSize: 14 }} spin />;
        return (
            <div hidden={!isLoading} className='epar_national_map_image-loading'>
                <Spin size="small" indicator={antIcon}/>
            </div>
        );
    }

    render() {
        const { s3HostUrl, nationalMapBucket } = CONFIG;
        const { site } = this.props;
        const climateChampion = site.team && site.team.climateChampions.length > 0 ? site.team.climateChampions[0] : {};
        return (
            <div>
                {
                    site &&
                    <div className='epar_national_map_detail-view'>
                        <Divider />
                        <Row justify='end' type='flex'>
                            <Button icon='edit' disabled={this.state.showEditSiteModal} onClick={() => this.setState({ showEditSiteModal: true })}>Edit Site</Button>
                        </Row>
                        <Col span={24}>
                            <p className='epar_national_map_form_category detail-title'>General Details</p>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>National Map Site Name : </b></Col>
                                <Col span={18}>{site.properties.siteName}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Short Description : </b></Col>
                                <Col span={18}>{site.properties.shortDescription}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Description : </b></Col>
                                <Col span={18}>{site.properties.siteDescription}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Traditional Custodians : </b></Col>
                                <Col span={18}>{site.properties.traditionalOwners}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Profile Image : </b></Col>
                                <Col span={18}>
                                    {/* {this.imageLoading(this.state.profileImageLoading)} */}
                                    <img onLoad={() => this.setState({ profileImageLoading: false })} src={`${s3HostUrl}/${nationalMapBucket}/${site.siteId}/profileImage.jpeg?${moment()}`} className='epar_national_map_image'/>
                                </Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Banner Image : </b></Col>
                                <Col span={18}>
                                    {/* {this.imageLoading(this.state.bannerImageLoading)} */}
                                    <img onLoad={() => this.setState({ bannerImageLoading: false })} src={`${s3HostUrl}/${nationalMapBucket}/${site.siteId}/bannerImage.jpeg?${moment()}`} loading={true} className='epar_national_map_image'/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} hidden={_.isEmpty(climateChampion)}>
                            <Divider />
                            <p className='epar_national_map_form_category detail-title'>Climate Champion Details</p>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Climate Champion's Name : </b></Col>
                                <Col span={18}>{climateChampion.climateChampionName}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Description : </b></Col>
                                <Col span={18}>{climateChampion.climateChampionDescription}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Profile Image : </b></Col>
                                <Col span={18}>
                                    {/* {this.imageLoading(this.state.climateChampionImageLoading)} */}
                                    <img onLoad={() => this.setState({ climateChampionImageLoading: false })} src={`${s3HostUrl}/${nationalMapBucket}/${site.siteId}/climateChampionImage.jpeg?${moment()}`} className='epar_national_map_image'/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Divider />
                            <p className='epar_national_map_form_category detail-title'>Contact Details</p>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Website : </b></Col>
                                <Col span={18}>{site.properties.website || '-'}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Phone Number : </b></Col>
                                <Col span={18}>{site.properties.contactNumber || '-'}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Site Area : </b></Col>
                                <Col span={18}>
                                    <Button onClick={() => this.setState({ showMapAreaModal: true })}>View Map</Button>
                                </Col>
                            </Row>
                        </Col>
                        {this.viewSiteAreaMap(site)}
                    </div>
                }
                {this.editSiteForm()}
            </div>
        );
    }
}

export default NationalMapDetailView;